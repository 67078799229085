/*
	Author: xowei <wei@xowei.tw>
	Website: https://xowei.tw/
*/

$(function () {
	var $body = $('body, html')
	var $gototop = $('.hud--gototop')
	var $preload = $('.preload')
	var $window = $(window)
	var windowScroll = 0

	$gototop.hide()

	$window.on('scroll', function () {
		windowScroll = $(window).scrollTop()

		if (windowScroll < window.innerHeight) {
			if ($gototop.length) $gototop.hide()
		} else {
			if ($gototop.length) $gototop.show()
		}
	})

	$(window).load(function () {
		$preload.fadeOut()
		initAnimate()
	})

	smoothScroll('a[href^="#"]')
	smoothScroll('#gototop')
	initLazyLoadImage()
	initNavbarCollapse()
	initDetectDevice()
	initNav()
	initCarousel()
	initHashLink()
	initForm()
	initModal()
	initGtag()
	initWinner()
})

function smoothScroll(target) {
	var $body = $('body, html')
	var $linkPosition
	var $nav = $('nav')

	$(target).each(function () {
		var $link = $(this)
		$linkPosition

		$link.on('click', function (e) {
			e.preventDefault()
			var tab = $link.attr('role')

			if (!tab) {
				var _href = $(this).attr('href')
				var $target = $(_href)
				$iconHeight = $('.product-title .icon').height()
				$linkPosition = ($target.length ? $target.offset().top : 0) - $nav.height()

				if ($target.is(':not(":first-child")')) {
					$linkPosition -= $iconHeight / 2
				}

				// console.log('_href', _href)

				if (_href === '#kv') {
					console.log('hi')
					history.pushState('', document.title, window.location.pathname + window.location.search)
				}

				$body.animate({
					scrollTop: $linkPosition,
				})
			}
		})
	})
}

function initAnimate() {
	$(`.section:not(#section-form) .section-title`).each(function () {
		$(this).attr({ 'data-aos': 'fade-up' })
	})

	$(`.section-block`).each(function () {
		$(this).attr({ 'data-aos': 'fade-in' })
		$(this).attr({ 'data-aos-delay': '200' })
	})

	// $(`.section-form`).each(function () {
	// 	$(this).attr({ 'data-aos': 'fade-up' })
	// 	$(this).attr({ 'data-aos-delay': '400' })

	// 	$(this)
	// 		.find('+ .text-center [type="submit"]')
	// 		.each(function () {
	// 			$(this).attr({ 'data-aos': 'fade-up' })
	// 			$(this).attr({ 'data-aos-delay': '400' })
	// 		})
	// })

	AOS.init({
		duration: 1000,
		// once: true,
	})
}

function initSvg() {
	$('.svg').each(function () {
		var $img = $(this)
		var imgID = $img.attr('id')
		var imgClass = $img.attr('class')
		var imgURL = $img.attr('src')
		// console.log($img)

		$.get(
			imgURL,
			function (data) {
				// console.log({
				// 	data,
				// })
				// Get the SVG tag, ignore the rest
				var $svg = $(data).find('svg')

				// Add replaced image's ID to the new SVG
				if (typeof imgID !== 'undefined') {
					$svg = $svg.attr('id', imgID)
				}
				// Add replaced image's classes to the new SVG
				if (typeof imgClass !== 'undefined') {
					$svg = $svg.attr('class', imgClass + ' replaced-svg')
				}

				// Remove any invalid XML tags as per http://validator.w3.org
				$svg = $svg.removeAttr('xmlns:a')

				// Replace image with new SVG
				$img.replaceWith($svg)
			},
			'xml',
		)
	})
}

function initGtag() {
	if (typeof gtag === 'undefined') return

	$('[data-gtag]').each(function () {
		let gtagEle = $(this)
		let gtagSetting = gtagEle.data('gtag')

		switch (gtagSetting.type) {
			case 'click':
				gtagEle.on('click', function (event) {
					console.log('ga', gtagSetting)
					gtag('event', gtagSetting.type, {
						event_category: gtagSetting.event_category,
						event_label: gtagSetting.event_label,
					})
				})
				break
			default:
				break
		}
	})
}

function initModal() {
	const $modal = $('.modal')
	$modal.modal()

	$modal.on('hidden.bs.modal', function (e) {
		$modal.find('.modal-title').text('')
		$modal.find('.modal-body').html('')
	})
}

function modalShow(title, content, modalSize = 'modal-sm', duration = 2000) {
	const $modal = $('.modal')
	const $modalDialog = $modal.find('.modal-dialog')
	$modalDialog.removeClass('modal-sm modal-md modal-lg modal-xl')
	$modalDialog.addClass(modalSize)
	$modal.modal('show')
	$modal.find('.modal-title').text(title)
	$modal.find('.modal-body').html(content)

	if (duration) {
		setTimeout(() => {
			$modal.modal('hide')
		}, duration)
	}
}

function initLazyLoadImage() {
	!(function (window) {
		var $q = function (q, res) {
				if (document.querySelectorAll) {
					res = document.querySelectorAll(q)
				} else {
					var d = document,
						a = d.styleSheets[0] || d.createStyleSheet()
					a.addRule(q, 'f:b')
					for (var l = d.all, b = 0, c = [], f = l.length; b < f; b++) l[b].currentStyle.f && c.push(l[b])

					a.removeRule(0)
					res = c
				}
				return res
			},
			addEventListener = function (evt, fn) {
				window.addEventListener
					? this.addEventListener(evt, fn, false)
					: window.attachEvent
					? this.attachEvent('on' + evt, fn)
					: (this['on' + evt] = fn)
			},
			_has = function (obj, key) {
				return Object.prototype.hasOwnProperty.call(obj, key)
			}
		function loadImage(el, fn) {
			var img = new Image(),
				src = el.getAttribute('data-src')
			img.onload = function () {
				if (!!el.parent) el.parent.replaceChild(img, el)
				else el.src = src

				fn ? fn() : null
			}
			if (src) img.src = src
		}

		function elementInViewport(el) {
			var rect = el.getBoundingClientRect()
			// console.log(rect)

			return (
				rect.top >= 0 && rect.left >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) * 2
			)
		}

		var images = new Array(),
			query = $q('img'),
			processScroll = function () {
				for (var i = 0; i < images.length; i++) {
					if (elementInViewport(images[i])) {
						loadImage(images[i], function () {
							images.splice(i, i)
						})
					}
				}
			}
		for (var i = 0; i < query.length; i++) {
			images.push(query[i])
		}

		processScroll()
		addEventListener('scroll', processScroll)
	})(this)
}

function initDetectDevice() {
	var classNames = []
	if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) classNames.push('device-ios')
	if (navigator.userAgent.match(/android/i)) classNames.push('device-android')
	if (navigator.userAgent.match(/Firefox/i)) classNames.push('device-firefox')
	if (navigator.userAgent.match(/Edg/i)) classNames.push('device-edge')
	if (navigator.userAgent.match(/Chrome/i)) classNames.push('device-chrome')

	var html = document.getElementsByTagName('html')[0]

	if (classNames.length) classNames.push('on-device')
	if (html.classList) html.classList.add.apply(html.classList, classNames)
}

function initNav() {
	$('.navbar a[href^="#"]:not(".dropdown-toggle")').on('click', function () {
		window.location.hash = $(this).attr('href')
		if ($(window).width() < 992) {
			$('.navbar-toggler').click()
		}
	})

	const collapse = document.querySelector('.navbar-collapse')
	const options = { attributes: true }

	function callback(mutationList, observer) {
		mutationList.forEach(function (mutation) {
			if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
				let showExist = $(mutation.target).hasClass('show')

				if ($(window).width() < 992) {
					if (showExist) {
						$('body').addClass('nav--open')
					} else {
						$('body').removeClass('nav--open')
					}
				}
			}
		})
	}

	if (!collapse) return

	const observer = new MutationObserver(callback)
	observer.observe(collapse, options)
}

function initCarousel() {
	$('.owl-carousel').each(function () {
		const _this = $(this)
		const { colorArrow = '#16365C', colorSwitcher, ...options } = _this.data('options')

		_this.owlCarousel({
			...options,
			navText: [
				`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.02 95.07"><rect x=".75" y=".75" width="38.52" height="93.57" transform="translate(40.02 95.07) rotate(180)" style="fill: #fff;"/><rect x=".75" y=".75" width="38.52" height="93.57" transform="translate(40.02 95.07) rotate(180)" style="fill: none; stroke: ${colorArrow}; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px;"/><polygon points="26.53 30.34 9.34 47.53 26.53 64.72 17.93 47.53 26.53 30.34" style="fill: ${colorArrow};"/></svg>`,
				`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.02 95.07"><rect x=".75" y=".75" width="38.52" height="93.57" style="fill: #fff;"/><rect x=".75" y=".75" width="38.52" height="93.57" style="fill: none; stroke: ${colorArrow}; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px;"/><polygon points="13.49 64.72 30.68 47.53 13.49 30.34 22.09 47.53 13.49 64.72" style="fill: ${colorArrow};"/></svg>`,
			],
		})

		_this.on({
			'changed.owl.carousel': (event) => {
				setTimeout(function () {
					const _tabs = $(colorSwitcher).find('.section-tabs-item')
					const _activeTab = _tabs.eq(event.page.index)
					const _items = _this.find('.owl-item')
					const _activeItem = _this.find('.owl-item.active').eq(1).prevObject
					const _videos = _items.find('.section-carousel-video iframe')
					const _activeVideo = _activeItem.find('.section-carousel-video iframe')

					_items.find('.aos-init').addClass('invisible').removeClass('aos-animate')

					setTimeout(() => {
						_activeItem.find('.aos-init').removeClass('invisible').addClass('aos-animate')
					}, 500)

					_tabs.removeClass('active')
					_activeTab.addClass('active')

					if (_videos && _activeVideo) {
						_videos.each(function () {
							$(this).attr('src', '')
						})

						_activeVideo.attr('src', _activeVideo.attr('data-src'))
					}

					// if ($(window).width() >= 768) return

					let _body = $('body, html')
					let tabsHalfHeight = $(colorSwitcher).height() / 2
					let pos = $(colorSwitcher).offset().top - $('.navbar').height() - tabsHalfHeight

					_body.animate({
						scrollTop: pos,
					})
				}, 10)
			},
		})

		if (colorSwitcher) {
			// const _switcher = _this.parentsUntil('.product').find(colorSwitcher)
			const _item = $(colorSwitcher).children()

			// console.log(_item)

			_item
				.addClass('clickable')
				// .attr('style', 'cursor: pointer;')
				.on('click', function (event) {
					let index = _item.index(this)
					// let _body = $('body, html')
					// let pos = _this.parentsUntil('.section').find('.section-content').offset().top - $('.navbar').height()
					let _lazyImg = _this.find('img')
					// console.log('index', index)
					_this.trigger('to.owl.carousel', index)
					_this.trigger('stop.owl.autoplay')

					_lazyImg.each(function () {
						let src = $(this).attr('src')
						if (src) return
						$(this).attr('src', $(this).data('src'))
					})

					// if ($(window).width() >= 768) return

					// _body.animate({
					// 	scrollTop: pos,
					// })
				})
		}
	})
}

function initNavbarCollapse() {
	$('.navbar-toggler-icon.is-open').hide()

	$('.navbar-collapse').on('shown.bs.collapse', function () {
		// $(this).parent().find('> .navbar-social').addClass('is-hide')
		$('.navbar-toggler-icon.is-collapse').hide()
		$('.navbar-toggler-icon.is-open').show()
	})

	$('.navbar-collapse').on('hidden.bs.collapse', function () {
		// $(this).parent().find('> .navbar-social').removeClass('is-hide')
		$('.navbar-toggler-icon.is-collapse').show()
		$('.navbar-toggler-icon.is-open').hide()
	})
}

function initHashLink() {
	let navHeight = $('.navbar').height()
	let hash = location.hash

	if (!hash || $(hash).length !== 1) return

	setTimeout(() => {
		window.scrollTo({ top: $(hash).offset().top - navHeight, behavior: 'instant' })
	}, 1000)
}

function initForm() {
	$('.form-control').each(function () {
		$(this)
			.on('keyup change', function () {
				if ($(this).val()) {
					$(this).addClass('hide-label')
				} else {
					$(this).removeClass('hide-label')
				}
			})
			.on('focus', function () {
				$(this).addClass('hide-label')
			})
			.on('blur', function () {
				if (!$(this).val()) $(this).removeClass('hide-label')
			})
	})

	$.extend($.validator.messages, {
		required: function (result, e) {
			let field_label = '此欄位'
			if ($(e).attr('placeholder')) field_label = $(e).attr('placeholder').split('(')[0]
			if ($(e).attr('id') === 'agree') return `請確認您已詳閱活動說明及注意事項`
			return `${field_label} 為必填`
		},
		minlength: $.validator.format('此欄位長度需大於 {0}'),
		maxlength: $.validator.format('此欄位長度需小於 {0}'),
		date: '請填寫正確的時間格式',
		email: '請填寫正確的 E-mail 格式',
		pattern: '請填寫正確的格式',
	})

	$('form')
		.on('submit', function (event) {
			event.preventDefault()
		})
		.validate({
			lang: 'zh-TW',
			submitHandler: function (form) {
				const $form = $(form)
				let data = $form.serialize()
				let $agreeInput = $form.find('input#agree')
				const agree = $agreeInput.is(':checked')

				if (!agree)
					return modalShow(
						'請確認您已詳閱活動說明及注意事項',
						'請選擇確認您已詳閱活動說明及注意事項，同意提供個資作為活動參加使用，並已確認輸入有效發票，保留有顯示購買商品的發票正本及購買明細，以利兌獎。',
						'modal-md',
						4000,
					)

				data += `&${$agreeInput.attr('name')}=${$agreeInput.val()}`

				$form.find('[type="submit"]').addClass('loading')

				gtag('event', '送出登錄發票', {
					event_category: '登錄發票表單傳送',
					event_label: '登錄發票',
				})

				setTimeout(() => {
					$form.find('[type="submit"]').removeClass('loading')
					$form.find('.clearable').val('')
				}, 1600)

				modalShow('發票登錄成功', '發票登錄成功，謝謝您參與活動，祝您中獎。')

				return submitGoogleForm(data, $form.data('survey_id'))
			},
			rules: {
				name: 'required',
				phone: 'required',
				email: {
					required: true,
					email: true,
				},
				receipt: 'required',
				date: 'required',
				'entry.1534373182': 'required',
			},
		})
}

function submitGoogleForm(string, survey_id) {
	const id = 'googleWeb'
	const googleUrl = `https://docs.google.com/forms/d/${survey_id}/formResponse?submit=Submit`

	const target = `${googleUrl}&${string}`

	if ($(`#${id}`).length) {
		return $(`#${id}`).attr('src', target)
	}

	return $('body').append(`<iframe style="display: none;" id="${id}" src="${target}"></iframe>`)
}

async function initWinner() {
	const _sectionWinner = $('#section-winner')
	if (!_sectionWinner.length) return

	const _list = _sectionWinner.find('[data-list]')
	let html = ''

	try {
		const result = await fetchJson(_list.data('list'))

		if (!result.showWinner) {
			_sectionWinner.hide()
			console.log('_sectionWinner', _sectionWinner)

			$(`[href="${_sectionWinner.selector}"]`).addClass('disabled')
			return
		}

		html = ''

		result.prize.map((item) => {
			let itemHtml = ''
			itemHtml = `
				<div class="row section-list-item align-items-center">
					<div class="col-lg-6 px-0">
						<div class="section-list-title">
							<span class="name" title="${item.name}"><span>${item.name}</span></span><span class="amount">x${item.name === '限量聯名悠遊卡' ? '每週' : ''}${item.name === '限量聯名悠遊卡' ? item.amount/10 : item.amount}名</span>${item.name === '限量聯名悠遊卡' ? `<span class="small">(共${item.amount}名)</span>` : ''}
						</div>
						<div class="section-list-image"><img src="${item.image}"></div>
					</div>
					<div class="col-lg-6 px-0">
						<div class="section-list-tag text-center">${item.timeDisplay}</div>
						<table class="section-table">
							<tbody>
								${item.list.length ? item.list
									.map((person) => {
										return `<tr>
											<td>${person.name}</td>
											<td>${person.invoice_number}</td>
											<td>${person.phone}</td>
										</tr>`
									})
									.join('') : `<tr><td><h4 class="fw-bold py-5">尚未公布</h4></td></tr>`}
							</tbody>
						</table>
					</div>
				</div>
			`
			html += itemHtml
		})
	} catch (error) {}

	_list.html(html)
}

const fetchJson = (url) => {
	return new Promise((resolve, reject) => {
		$.ajax({
			url,
		})
			.done(function (data) {
				return resolve(data)
			})
			.fail(function (error) {
				return reject(error)
			})
	})
}

function Utils() {}

Utils.prototype = {
	constructor: Utils,
	isElementInView: function (element, fullyInView) {
		var pageTop = $(window).scrollTop()
		var pageBottom = pageTop + $(window).height()
		var elementTop = $(element).offset().top
		var elementBottom = elementTop + $(element).height()

		if (fullyInView === true) {
			return pageTop < elementTop && pageBottom > elementBottom
		} else {
			return elementTop <= pageBottom && elementBottom >= pageTop
		}
	},
	smoothScroll: function () {
		$('a[href^="#"]').on('click', function (e) {
			var $target = $(e.currentTarget.hash)

			$('html,body').animate(
				{
					scrollTop: $target.offset().top,
				},
				700,
			)

			e.preventDefault()
		})
	},
	detectViewport: function () {
		return { height: window.innerHeight, width: window.innerWidth }
	},
}
